










import Vue from 'vue';

export default Vue.extend({
  components: {},
  data() {
    return {};
  },
  methods: {},
});
