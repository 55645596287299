


































import Vue from 'vue';
import TopMenu from './TopMenu.vue';
import BottomMenu from './BottomMenu.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  components: { TopMenu, BottomMenu, LoadingSpinner },
  props: {
    title: {
      type: String,
      required: false,
    },
    isContentScrollable: {
      type: Boolean,
      required: false,
      default: true,
    },
    selectedMenuItem: {
      type: String,
      required: false,
    },
    hideBottomMenu: {
      type: Boolean,
      required: false,
      default: false,
    },
    showBackButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      loading: 'isLoading',
    }),
  },
});
