











































import Vue from 'vue';
export default Vue.extend({
  name: 'TopMenu',
  props: {
    title: {
      type: String,
      required: false,
    },
    showBackButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
