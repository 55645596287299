














































import Vue from 'vue';
import DiscoverIcon from '@/assets/mobile/icons/discover-icon.svg';
import DiscoverIconSelected from '@/assets/mobile/icons/discover-icon-selected.svg';

import ClubsIcon from '@/assets/mobile/icons/clubs-icon.svg';
import ClubsIconSelected from '@/assets/mobile/icons/clubs-icon-selected.svg';

import FollowingIcon from '@/assets/mobile/icons/following-icon.svg';
import FollowingIconSelected from '@/assets/mobile/icons/following-icon-selected.svg';

import ProfileIcon from '@/assets/mobile/icons/profile-icon.svg';
import ProfileIconSelected from '@/assets/mobile/icons/profile-icon-selected.svg';

export default Vue.extend({
  components: {
    DiscoverIcon,
    DiscoverIconSelected,
    ClubsIcon,
    ClubsIconSelected,
    ProfileIcon,
    ProfileIconSelected,
    FollowingIcon,
    FollowingIconSelected,
  },
  props: {
    selectedMenuItem: {
      type: String,
      required: true,
      default: 'reactions',
    },
  },
});
